import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import { getIndex } from '../util'
import { renderAst } from '../markdown'

export default ({ data }) => {
    const content = renderAst(getIndex(data))
    return <Layout>{content}</Layout>
}

export const pageQuery = graphql`
    query {
        allMarkdownRemark {
            edges {
                node {
                    htmlAst
                    frontmatter {
                        id
                    }
                }
            }
        }
    }
`
